import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import axios from 'axios';
import { URL } from '../utils/utils';
import articlePlaceholder from '../images/default-post-image.svg'


const StyledWrapper = styled.div`
  margin-bottom: 120px;

  @media(max-width: 1184px) {
    margin-bottom: 0;
    padding: 0px 2.5%;
  }
`;

const ArticlesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.alignLeft ? 'flex-start' : 'center'};
`;

const TextOnImage = styled.div`
  position: absolute;
  background-color: rgba(32, 59, 188, 0.9);
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: .5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Blog = styled.div`
  height: 200px;
  position: relative;
  cursor: pointer;
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  margin: 20px;
  margin: ${props => props.alignLeft ? '20px 40px 20px 0px' : '20px'};

  :hover ${TextOnImage} {
    opacity: 1;
  }
`;

const Img = styled.img`
  height: 100%;
`;

const H5 = styled.h5`
  font-size: 18px;
  text-align: center;
  padding: 0 20px;
`;

const P = styled.p`
  line-height: 23px;
  color: #fff;
  text-align: center;
  margin: 0 22px;  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  padding: 15px;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding: 15px;
    display: none;
  }

  p:first-of-type {
    display: inline;
  }

  @media(max-width: 1184px) {
    font-size: 12px;
  }
`;

class BlogSection extends Component {
  state = {
    checkedItems: new Map(),
    filters: null,
    posts: null
  }

  componentDidMount() {
    axios.get(`${URL}/api/tags`)
      .then(response => {
        this.setState({filters: response.data})
      })
      .then(() => {
        this.state.filters.forEach(filter => {
          this.setState(prevState => ({
              checkedItems: prevState.checkedItems.set(filter.name, false)
          }));
        });
      })
      .catch(err => console.log(err));

    axios.get(`${URL}/api/posts`)
      .then(response => {
        this.setState({posts: response.data});
      })
      .catch(err => console.log(err));
  }

  updateFilter = buttonName => {
    let filteredItemsUrl = `${URL}/api/posts?`;

    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(buttonName, !prevState.checkedItems.get(buttonName))
    }), () => {
      for(let [key, value] of this.state.checkedItems) {
        if(value) {
          filteredItemsUrl += `tags[]=${key}&`;
        }
      }
      axios.get(filteredItemsUrl)
        .then(response => {
          this.setState({posts: response.data})
        })
        .catch(error => console.log(error));
    });
  }

  render() {
    let blogPosts;

    if (this.state.posts) {
      blogPosts = this.state.posts.map(blogPost => (
        <Link
          key={blogPost.id}
          to={`/aktuell/?publikationen=${blogPost.slug}`}>
          <Blog alignLeft={this.props.alignLeft} >
            <Img src={blogPost.image_url || articlePlaceholder} alt={blogPost.image_alt_text || blogPost.name} />
            <TextOnImage>
              <H5>{blogPost.name}</H5>
              <P dangerouslySetInnerHTML={{ __html: blogPost.description }}></P>
            </TextOnImage>
          </Blog>
        </Link>
      ));

      if (this.props.limit) {
        blogPosts = blogPosts.slice(0, this.props.limit)
      }
    }

    return (
      <React.Fragment>
        <StyledWrapper id="blog" className="scrollSection">
          <ArticlesSection alignLeft={this.props.alignLeft} >
            {blogPosts}
          </ArticlesSection>
        </StyledWrapper>
      </React.Fragment>
    )
  }
}

export default BlogSection;
