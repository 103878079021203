import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #0b164a;
  min-width: 200px;
  max-height: 70%;
  transform: translate(-50%, -50%);
  z-index: 200;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  overflow: auto;
  border: 3px solid rgb(200, 210, 255);

  @media(max-width: 1184px) {
    max-height: 80%;
    width: 80%;
  }

  h5 {
    margin-bottom: 24px;
  }

  p {
    text-align: left;
  }

  ul {
    list-style: circle;
    color: white;
  }

  li {
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 12px;

    p {
      line-height: 26px;
      text-align: left;
    }
  }

  ul.no-grid {
    @media(max-width: 1184px) {
      padding: 8px 16px;

      li {
        font-size: 12px;
        display: block;
        text-align: left;
        margin-bottom: 0;
      }
    }
  }

  @media(max-width: 1184px) {
    padding: 16px;
  }
`;

const modal = props => {
  return (
    props.show
      ? <React.Fragment>
        <Backdrop onClick={props.clicked} />
        <ModalWrapper id="modal">
          {props.children}
        </ModalWrapper>
      </React.Fragment>
      : null
  )
};

export default modal;